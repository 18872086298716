//
// Font-face generator for all the fonts of the project,
// will be useful to add different files for each font.
//

@import 'mixins/fonts';

@include font-include(Roboto, Regular, get-font-weight(regular), normal);
@include font-include(Roboto, Medium, get-font-weight(medium), normal);
@include font-include(Roboto, Bold, get-font-weight(bold), normal);
@include font-include(Roboto, Regular, get-font-weight(regular), Italic);
@include font-include(Roboto, Medium, get-font-weight(medium), Italic);
@include font-include(Roboto, Bold, get-font-weight(bold), Italic);
