@import '../../../../styles/base/colors';
@import '../../../../styles/base/mixins/generic-get';
@import '../../../../styles/base/mixins/media-queries';

$base-size: 16;

@function get-color($namespace: primary, $variance: main) {
  $color-map: get($color, $namespace);
  @return get($color-map, $variance);
}

@function get-font-weight($key: medium) {
  @return get($font-weight, $key);
}

@function get-font-size($key: m) {
  @return get($font-size, $key);
}

@function get-var($key) {
  @return get($vars, $key);
}

@function rem($target, $context: $base-size) {
  @return ($target / $context) * 1rem;
}

$font-weight: (
  regular: 400,
  medium: 500,
  bold: 700
);

// [12px, 13px, 16px, 18px, 20px, 24px, 28px]
$font-size: (
  xss: 0.75rem,
  xs: 0.8125rem,
  sm: 1rem,
  md: 1.125rem,
  lg: 1.25rem,
  xl: 1.5rem,
  xll: 1.75rem
);

$breakpoints: (
  xss: 575px,
  xs: 640px,
  sm: 768px,
  md: 1024px,
  lg: 1280px,
  xl: 1440px,
  xxl: 1920px,
  xxxl: 2560px,
  4k: 3840px
);

$vars: (
  alertDisplayTime: 350ms,
  containerMaxWith: 144rem,
  headerHeight: 10rem,
  headerHeightMobile: 7rem,
  zIndexNav: 1000
);

:export {
  @each $key, $value in $breakpoints {
    media-#{$key}: $value;
  }
  @each $key, $value in $font-size {
    font-size-#{$key}: $value;
  }
  @each $key, $value in $vars {
    #{$key}: $value;
  }
}

.nav {
  &__container {
    list-style: none;

    > li {
      display: inline-block;
    }
  }

  &__item {
    padding: 0.5rem 1.5rem;

    @include media(md) {
      padding: 0.5rem 1rem;
    }
  }

  &__link {
    font-size: 1.6rem;
    text-decoration: none;

    @include media(md) {
      font-size: 1.4rem;
    }

    &:link,
    &:visited {
      color: get-color(basic, brightest);
    }

    &:hover,
    &:active,
    &.active {
      color: get-color(secondary, main);
    }
  }

  &-mobile {
    &__toggle {
      cursor: pointer;

      &-btn {
        width: 4rem;
        height: 2.6rem;
        position: relative;
        transform: rotate(0deg);
        transition: 0.5s ease-in-out;
        cursor: pointer;

        & span {
          display: block;
          position: absolute;
          height: 4px;
          width: 100%;
          background: get-color(secondary, main);
          border-radius: 0.9rem;
          opacity: 1;
          left: 0;
          transform: rotate(0deg);
          transition: 0.25s ease-in-out;

          &:nth-child(1) {
            top: 0;
          }
          &:nth-child(2),
          &:nth-child(3) {
            top: 1.1rem;
          }
          &:nth-child(4) {
            top: 2.2rem;
          }
        }

        &.open {
          span:nth-child(1),
          span:nth-child(4) {
            top: 1.8rem;
            width: 0;
            left: 50%;
          }

          span:nth-child(2) {
            transform: rotate(45deg);
          }

          span:nth-child(3) {
            transform: rotate(-45deg);
          }
        }
      }
    }

    &__backdrop {
      position: fixed;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
      top: 7rem;
      left: 0;
      z-index: calc(#{get-var(zIndexNav)} - 5);
    }

    &__items-wrapper {
      position: fixed;
      z-index: get-var(zIndexNav);
      width: 100%;
      max-width: 35rem;
      top: 0;
      left: 0;
      height: 100%;
      background: url(../../../../assets/images/noisy-100x100.png);
      transform: translateX(-100%);
      transition: transform 0.3s ease-in;
      border-radius: 0 2.5rem 2.5rem 0;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      overflow-y: auto;

      @include media(xss) {
        max-width: 25rem;
      }

      svg {
        max-width: 25rem;
        max-height: 8rem;

        @include media(xss) {
          max-width: 20rem;
        }
      }

      &.open {
        transform: translateX(0);
      }

      .nav__container {
        > li {
          display: block;
        }
      }
    }

    &__logo-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__social-container {
      display: flex;
      margin-top: 2.5rem;

      > * {
        margin-right: 1.5rem;
      }
    }

    &__items-container {
      margin-top: 3rem;

      .button-lang__button {
        margin-top: 0.5rem;
        font-size: 2.5rem;
      }
    }

    .nav__link {
      font-size: 2.5rem;
    }
  }
}
