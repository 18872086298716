@import '../../../styles/base/colors';
@import '../../../styles/base/mixins/generic-get';
@import '../../../styles/base/mixins/media-queries';

$base-size: 16;

@function get-color($namespace: primary, $variance: main) {
  $color-map: get($color, $namespace);
  @return get($color-map, $variance);
}

@function get-font-weight($key: medium) {
  @return get($font-weight, $key);
}

@function get-font-size($key: m) {
  @return get($font-size, $key);
}

@function get-var($key) {
  @return get($vars, $key);
}

@function rem($target, $context: $base-size) {
  @return ($target / $context) * 1rem;
}

$font-weight: (
  regular: 400,
  medium: 500,
  bold: 700
);

// [12px, 13px, 16px, 18px, 20px, 24px, 28px]
$font-size: (
  xss: 0.75rem,
  xs: 0.8125rem,
  sm: 1rem,
  md: 1.125rem,
  lg: 1.25rem,
  xl: 1.5rem,
  xll: 1.75rem
);

$breakpoints: (
  xss: 575px,
  xs: 640px,
  sm: 768px,
  md: 1024px,
  lg: 1280px,
  xl: 1440px,
  xxl: 1920px,
  xxxl: 2560px,
  4k: 3840px
);

$vars: (
  alertDisplayTime: 350ms,
  containerMaxWith: 144rem,
  headerHeight: 10rem,
  headerHeightMobile: 7rem,
  zIndexNav: 1000
);

:export {
  @each $key, $value in $breakpoints {
    media-#{$key}: $value;
  }
  @each $key, $value in $font-size {
    font-size-#{$key}: $value;
  }
  @each $key, $value in $vars {
    #{$key}: $value;
  }
}

.icon {
  &__wrapper {
    color: get-color(basic, darkest);
    background-color: get-color(basic, brightest);
    border-radius: 50%;
    transition: color 0.2s ease-in;
    cursor: pointer;

    &.no-bg {
      background-color: transparent;
    }

    &.invert {
      color: get-color(basic, brightest);
      background-color: get-color(basic, darkest);

      svg {
        fill: get-color(basic, brightest);
      }
    }

    &.icon-white {
      color: get-color(basic, brightest);
    }

    &:not(.no-hover):hover {
      color: get-color(secondary, main);
    }

    &.inline {
      display: inline-block;
    }

    &.btn-icon {
      display: inline-block;
      background-color: transparent;
      width: 2.8rem !important;
      height: 2.8rem !important;

      svg {
        width: 2rem;
        height: 12rem;
        margin-right: 0.8rem;
      }
    }

    &.large {
      width: 10rem;
      height: 10rem;

      svg {
        width: 5rem;
        height: 5rem;
      }
    }

    &.medium {
      width: 7.5rem;
      height: 7.5rem;

      svg {
        width: 3.75rem;
        height: 3.75rem;
      }
    }

    &.small {
      width: 5rem;
      height: 5rem;

      svg {
        width: 2.5rem;
        height: 2.5rem;
      }
    }

    &.tiny {
      width: 3.5rem;
      height: 3.5rem;

      svg {
        width: 1.75rem;
        height: 1.75rem;
      }
    }

    svg {
      fill: currentColor;
    }
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: currentColor;

    svg {
      display: block;
      fill: currentColor;
    }
  }
}
