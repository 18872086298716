/* stylelint-disable */

@mixin media($breakpoint, $minMax: 'max', $mediaType: 'all') {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    @if $mediaType == 'all' {
      @media (if($minMax == 'max', max-width, min-width): ($breakpoint-value)) {
        @content;
      }
    } @else {
      @media #{$mediaType} and (if($minMax == 'max', max-width, min-width): ($breakpoint-value)) {
        @content;
      }
    }
  } @else {
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}
