@keyframes flicker1 {
  0% {
    opacity: 0.6;
  }
  5% {
    opacity: 0.7;
  }
  10% {
    opacity: 0.8;
  }
  15% {
    opacity: 0.85;
  }
  25% {
    opacity: 0.6;
  }
  30% {
    opacity: 1;
  }
  35% {
    opacity: 0.7;
  }
  40% {
    opacity: 0.8;
  }
  45% {
    opacity: 0.9;
  }
  60% {
    opacity: 1;
  }
  70% {
    opacity: 0.85;
  }
  80% {
    opacity: 0.7;
  }
  90% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}
