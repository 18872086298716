* {
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

//html,
//body,
//#root,
//.app {
//  height: 100%;
//}

html,
body {
  height: 100%;
  width: 100%;
}

#root,
.app {
  height: 100%;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  //height: 100%;
  //width: 100%;
  //min-font-size: 100%;
}

body {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  line-height: 1.6;
  color: get-color(primary, main);
  //height: 100%;
  //width: 100%;

  &.no-scroll {
    overflow-y: hidden;
  }
}

#root {
  display: flex;
  //flex-direction: column;

  //height: 100%;
  width: 100%;
}

.app {
  width: 100%;
  //height: 100%;
}
