$color-primary: (
  darker: #030303,
  main: #020604,
  lighter: #37373f
);

$color-secondary: (
  darker: #967f1b,
  main: #fcd639,
  lighter: #fcf939
);

$color-greyscale: (
  100: #f8f9fa,
  200: #e8ebee,
  300: #d4d9de,
  400: #c1c7ce,
  500: #bfc1c0,
  600: #8c8e8d,
  700: #595b5a,
  800: #343a40,
  900: #212529
);

$color-basic: (
  brightest: #fefefe,
  darkest: #000000,
  darker: #252525,
  errorRed: #f44336,
  successGreen: #18a164,
  warningOrange: #ff9803,
  brown: #401e1b,
  darkRed: #99151b,
  noStepBack: #e8c42c
);

$color: (
  primary: $color-primary,
  secondary: $color-secondary,
  basic: $color-basic,
  greyscale: $color-greyscale
);

:export {
  @each $key, $value in $color {
    @each $subkey, $subvalue in $value {
      #{$key}-#{$subkey}: $subvalue;
    }
  }
}
