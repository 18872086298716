@import '../../../../styles/base/colors';
@import '../../../../styles/base/mixins/generic-get';
@import '../../../../styles/base/mixins/media-queries';

$base-size: 16;

@function get-color($namespace: primary, $variance: main) {
  $color-map: get($color, $namespace);
  @return get($color-map, $variance);
}

@function get-font-weight($key: medium) {
  @return get($font-weight, $key);
}

@function get-font-size($key: m) {
  @return get($font-size, $key);
}

@function get-var($key) {
  @return get($vars, $key);
}

@function rem($target, $context: $base-size) {
  @return ($target / $context) * 1rem;
}

$font-weight: (
  regular: 400,
  medium: 500,
  bold: 700
);

// [12px, 13px, 16px, 18px, 20px, 24px, 28px]
$font-size: (
  xss: 0.75rem,
  xs: 0.8125rem,
  sm: 1rem,
  md: 1.125rem,
  lg: 1.25rem,
  xl: 1.5rem,
  xll: 1.75rem
);

$breakpoints: (
  xss: 575px,
  xs: 640px,
  sm: 768px,
  md: 1024px,
  lg: 1280px,
  xl: 1440px,
  xxl: 1920px,
  xxxl: 2560px,
  4k: 3840px
);

$vars: (
  alertDisplayTime: 350ms,
  containerMaxWith: 144rem,
  headerHeight: 10rem,
  headerHeightMobile: 7rem,
  zIndexNav: 1000
);

:export {
  @each $key, $value in $breakpoints {
    media-#{$key}: $value;
  }
  @each $key, $value in $font-size {
    font-size-#{$key}: $value;
  }
  @each $key, $value in $vars {
    #{$key}: $value;
  }
}

.footer {
  margin-top: auto;
  width: 100%;
  background-color: get-color(basic, darker);
  color: get-color(basic, brightest);
  padding: 2rem;

  @include media(sm) {
    padding: 1.5rem 2rem;
  }

  //@include media(xss) {
  //  margin-top: unset;
  //}

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

    @include media(sm) {
      flex-direction: column;
    }
  }

  &__logo {
    @include media(sm) {
      margin-bottom: 1.5rem;
    }

    svg {
      max-width: 6.5rem;
      max-height: 6.5rem;

      @include media(md) {
        max-width: 6rem;
        max-height: 6rem;
      }

      @include media(sm) {
        max-width: 5rem;
        max-height: 5rem;
      }
    }
  }

  &__nav {
    @include media(sm) {
      margin-bottom: 1.5rem;
    }
  }

  &__social {
    display: inline-flex;
    gap: 1.5rem;

    @include media(sm) {
      margin-bottom: 1.5rem;
    }
  }

  &__copyright {
    padding: 1rem 2rem;
    width: 100%;
    text-align: center;
  }
}
