//*******************************
//   LINKS
//*******************************

a:link,
a:visited {
  color: get-color(basic, brightest);
  transition: color 0.3s ease-in;
}

a:hover,
a:active,
a.active {
  color: get-color(secondary, main);
}

a.default:hover,
a.default:active,
a.default.active,
a.default:link,
a.default:visited {
  transition: unset;
  color: currentColor;
}

//*******************************
//   HEADINGS
//*******************************

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.8rem;
}

h1 {
  font-size: 3.6rem;
  line-height: 4rem;

  &.huge {
    font-size: 4.8rem;
    line-height: 5rem;

    &:after {
      content: '';
      display: block;
      width: 13rem;
      height: 0.7rem;
      margin-top: 1.8rem;
      background-color: currentColor;
    }
  }
}

h2 {
  font-size: 3rem;
  line-height: 4rem;
}

h3 {
  font-size: 2.4rem;
  line-height: 4rem;
}

h4 {
  font-size: 1.8rem;
  line-height: 2rem;
}

h5 {
  font-size: 1.4rem;
  line-height: 2rem;
}

h6 {
  font-size: 1.2rem;
  line-height: 2rem;
}

//*******************************
//   COMMON CSS
//*******************************

iframe {
  border: 0;
}

.main {
  height: 100%;
}

.full-with-container {
  width: 100%;
}

.container {
  margin: 0 auto;
  max-width: get-var(containerMaxWith);
  width: 100%;
}

.inner-section {
  padding: 5rem 10rem;

  @include media(md) {
    padding: 2rem 5rem;
  }

  @include media(sm) {
    padding: 2rem;
  }

  @include media(sm) {
    padding: 2rem 0;
  }
}

//*******************************
//   FORM CSS
//*******************************

.form-row {
  width: 100%;
  display: flex;
  gap: 2rem;

  @include media(xs) {
    flex-direction: column;
    gap: 0;
  }
}

.form-group {
  width: 100%;
  margin-bottom: 2rem;

  &--no-margin {
    margin-bottom: 0;
  }
}

.form-control {
  &:focus,
  &:hover {
    outline: 0;
    border-color: get-color(basic, brightest);
  }
}

.input,
.text-area {
  width: 100%;
  margin-bottom: 0;
  border: 1px solid #626267;
  border-radius: 6px;
  color: #8f8f94;
  font-size: 1.8rem;
  font-family: Roboto, sans-serif;
  background-color: transparent;
  transition: border-color 350ms ease;
}

.input {
  height: 6.5rem;
  padding-right: 2.4rem;
  padding-left: 2.4rem;
}

.text-area {
  min-height: 16rem;
  padding: 1.4rem 2.4rem;
  border-radius: 6px;
}

//*******************************
//   UTILITIES CSS
//*******************************

.mb0 {
  margin-bottom: 0;
}

.mb1 {
  margin-bottom: 1rem;
}

.mb2 {
  margin-bottom: 2rem;
}

.mb3 {
  margin-bottom: 3rem;
}

.mb4 {
  margin-bottom: 4rem;
}

.mb5 {
  margin-bottom: 5rem;
}

.mb6 {
  margin-bottom: 6rem;
}

.mb7 {
  margin-bottom: 7rem;
}

.mb8 {
  margin-bottom: 8rem;
}

.pt1 {
  padding-top: 1rem;
}

.pt2 {
  padding-top: 2rem;
}

.pt3 {
  padding-top: 3rem;
}

.pt4 {
  padding-top: 4rem;
}

.pt5 {
  padding-top: 5rem;
}

.pt6 {
  padding-top: 6rem;
}

.pt7 {
  padding-top: 7rem;
}

.pt8 {
  padding-top: 8rem;
}
